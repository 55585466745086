.orderItems-3QG1Ebgd {
  margin-bottom: 28px;
}

.content-oRqKNf3V {
  margin: 58px auto;
  width: var(--content-width);
}

.orderCard-D08TcDZW:not(:last-child) {
  margin-bottom: 58px;
}

.card-2waU9Xzq {
  width: 33.33%;
  min-width: 250px;
  padding-right: 20px;
}

.spinner-2_GKB5PU {
  margin-top: 50px;
}

@media screen and (max-width: 760px) {
  .card-2waU9Xzq {
    width: 100%;
    margin-bottom: 20px;
  }
}

.modal-2vwydYrc {
  max-height: 55vh;
  overflow-x: hidden;
  overflow-y: auto;
}
