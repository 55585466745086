.zuoraPayment-2Bgj99Vy {
  min-height: 256px;
}

.zuoraPayment-2Bgj99Vy iframe {
  width: 100%;
  min-height: 490px;
  background-color: transparent;
}

/*@media (max-width: 640px) {
  .zuoraPayment iframe {
    height: 250px;
  }
}*/

.input-checkbox-ehWoDr-N {
  overflow: hidden;
  position: relative;
  margin-bottom: 20px;
  margin-top: 10px;
}

.input-checkbox-ehWoDr-N label {
  display: flex;
  align-items: center;
}

.input-checkbox-ehWoDr-N input[type='checkbox'] {
  position: absolute;
  left: -9999px;
}

.input-checkbox-ehWoDr-N input[type='checkbox']:disabled + .input-checkbox-checkmark-2XSBFGBv {
  opacity: 0.2;
}

.input-checkbox-checkmark-2XSBFGBv {
  display: inline-block;
  position: relative;
  width: 20px;
  height: 20px;
  border: solid 2px var(--secondary-text-color);
  border-radius: 2px;
  background-color: var(--primary-backgroud-color);
  margin: 0 8px 0 0;
  vertical-align: top;
  cursor: pointer;
}

.input-checkbox-checkmark-2XSBFGBv svg {
  position: absolute;
  top: -2px;
  left: -2px;
}

.input-checkbox-checkmark-2XSBFGBv:hover {
  border: solid 2px var(--tertiary-text-color);
}

.theme-promo-sunrise .input-checkbox-checkmark-2XSBFGBv:hover {
  border: solid 2px #e6003c;
}

.input-checkbox-label-s6F2p-Zh {
  display: inline-block;
  font-size: 15px;
  font-weight: 400;
  color: var(--secondary-text-color);
  cursor: pointer;
  user-select: none;
}

.input-checkbox-checkmark-active-1LfDxOPM {
  background-color: var(--tertiary-text-color);
  border: solid 2px var(--tertiary-text-color);
}

.theme-promo-sunrise .input-checkbox-checkmark-active-1LfDxOPM {
  background-color: #e6003c;;
  border-color: #e6003c;
}


.formActions-2jWPJ6nY {
  margin-bottom: 80px;
}

.submitBtn-24lTFmFT {
  position: absolute;
}

