/* Font sizes */

.title-xl {
  font-size: 44px;
  line-height: 1.2;
}

.title-l {
  font-size: 36px;
  line-height: 1.2;
}

.title-m {
  font-size: 26px;
  line-height: 1.2;
}

.default-l {
  font-size: 18px;
  line-height: 1.4;
}

.default-m {
  font-size: 16px;
  line-height: 1.4;
}

.default-s {
  font-size: 13px;
}

.default-xs {
  font-size: 11px;
}

.uppercase-s {
  font-size: 13px;
  line-height: 1.2;
  letter-spacing: 1.2px;
  text-transform: uppercase;
}

/* Font weight */
.light {
  font-weight: 300;
}

.regular {
  font-weight: 400;
}

.semi-bold {
  font-weight: 500;
}

.bold {
  font-weight: 700;
}

/* Mobile font sizes */

@media(max-width: 992px) {
  .title-xl,
  .title-l {
    font-size: 28px;
    line-height: 1.25;
  }

  .default-l {
    font-size: 15px;
  }
}

@media(max-width: 760px) {

  .title-xl,
  .title-l {
    font-size: 24px;
    line-height: 1.25;
  }
}