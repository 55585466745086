.zuoraPayment-2lmfrMLZ {
  min-height: 256px;
}

.zuoraPayment-2lmfrMLZ iframe {
  width: 100%;
  min-height: 490px;
  background-color: transparent;
}

/*@media (max-width: 640px) {
  .zuoraPayment iframe {
    height: 250px;
  }
}*/

.input-checkbox-2g7_3Mws {
  overflow: hidden;
  position: relative;
  margin-bottom: 20px;
  margin-top: 10px;
}

.input-checkbox-2g7_3Mws label {
  display: flex;
  align-items: center;
}

.input-checkbox-2g7_3Mws input[type='checkbox'] {
  position: absolute;
  left: -9999px;
}

.input-checkbox-2g7_3Mws input[type='checkbox']:disabled + .input-checkbox-checkmark-1gyeCGN3 {
  opacity: 0.2;
}

.input-checkbox-checkmark-1gyeCGN3 {
  display: inline-block;
  position: relative;
  width: 20px;
  height: 20px;
  border: solid 2px var(--secondary-text-color);
  border-radius: 2px;
  background-color: var(--primary-backgroud-color);
  margin: 0 8px 0 0;
  vertical-align: top;
  cursor: pointer;
}

.input-checkbox-checkmark-1gyeCGN3 svg {
  position: absolute;
  top: -2px;
  left: -2px;
}

.input-checkbox-checkmark-1gyeCGN3:hover {
  border: solid 2px var(--tertiary-text-color);
}

.theme-promo-sunrise .input-checkbox-checkmark-1gyeCGN3:hover {
  border: solid 2px #e6003c;
}

.input-checkbox-label-1DuwXowq {
  display: inline-block;
  font-size: 15px;
  font-weight: 400;
  color: var(--secondary-text-color);
  cursor: pointer;
  user-select: none;
}

.input-checkbox-checkmark-active-3gTmv-HE {
  background-color: var(--tertiary-text-color);
  border: solid 2px var(--tertiary-text-color);
}

.theme-promo-sunrise .input-checkbox-checkmark-active-3gTmv-HE {
  background-color: #e6003c;;
  border-color: #e6003c;
}


.formActions-3M-RG_qT {
  margin-bottom: 80px;
}

.submitBtn-3NqcV9WE {
  position: absolute;
}

