.webp .home-adaptive-wifi-bg {
  background-image: url("/static/images/illustration-plume-never-a-dull-moment.webp");
}

.no-webp .home-adaptive-wifi-bg {
  background-image: url("/static/images/illustration-plume-never-a-dull-moment.png");
}

.webp .home-parental-controls-bg {
  background-image: url("/static/images/illustration-plume-make-internet-a-safer-place.webp");
}

.no-webp .home-parental-controls-bg {
  background-image: url("/static/images/illustration-plume-make-internet-a-safer-place.png");
}

.webp .pod-innards-bg {
  background-image: url("/static/images/pod-innards-big.webp");
}

.no-webp .pod-innards-bg {
  background-image: url("/static/images/pod-innards-big.png");
}

.webp .about-hero-bg {
  background-image: url("/static/images/about-hero-big.webp");
}

.no-webp .about-hero-bg {
  background-image: url("/static/images/about-hero-big.jpg");
}

@media only screen and (max-width: 760px) {
  .webp .about-hero-bg {
    background-image: url("/static/images/about-hero-small.webp");
  }

  .no-webp .about-hero-bg {
    background-image: url("/static/images/about-hero-small.jpg");
  }
}

.webp .careers-hero-bg {
  background-image: url("/static/images/careers-hero-big.webp");
}

.no-webp .careers-hero-bg {
  background-image: url("/static/images/careers-hero-big.jpg");
}

.webp .explore-app-hero-bg {
  background-image: url("/static/images/hero-explore-app.webp");
}

.no-webp .explore-app-hero-bg {
  background-image: url("/static/images/hero-explore-app.png");
}

.webp .explore-app-simple-setup-bg {
  background-image: url("/static/images/img-simple-setup.webp");
}

.no-webp .explore-app-simple-setup-bg {
  background-image: url("/static/images/img-simple-setup.png");
}

.webp .explore-app-wifi-family-bg {
  background-image: url("/static/images/photo-kid.webp");
}

.no-webp .explore-app-wifi-family-bg {
  background-image: url("/static/images/photo-kid.png");
}


.no-webp .explore-app-wifi-family-bg {
  background-image: url("/static/images/photo-kid.png");
}
