.zuoraPayment-z6wez4yD {
  min-height: 256px;
}

.zuoraPayment-z6wez4yD iframe {
  width: 100%;
  min-height: 256px;
  background-color: transparent;
}

/*@media (max-width: 640px) {
  .zuoraPayment iframe {
    height: 250px;
  }
}*/

.input-checkbox-kH2er198 {
  overflow: hidden;
  position: relative;
  margin-bottom: 20px;
  margin-top: 10px;
}

.input-checkbox-kH2er198 label {
  display: flex;
  align-items: center;
}

.input-checkbox-kH2er198 input[type='checkbox'] {
  position: absolute;
  left: -9999px;
}

.input-checkbox-kH2er198 input[type='checkbox']:disabled + .input-checkbox-checkmark-_vtbG2d_ {
  opacity: 0.2;
}

.input-checkbox-checkmark-_vtbG2d_ {
  display: inline-block;
  position: relative;
  width: 20px;
  height: 20px;
  border: solid 2px var(--secondary-text-color);
  border-radius: 2px;
  background-color: var(--primary-backgroud-color);
  margin: 0 8px 0 0;
  vertical-align: top;
  cursor: pointer;
}

.input-checkbox-checkmark-_vtbG2d_ svg {
  position: absolute;
  top: -2px;
  left: -2px;
}

.input-checkbox-checkmark-_vtbG2d_:hover {
  border: solid 2px var(--tertiary-text-color);
}

.theme-promo-sunrise .input-checkbox-checkmark-_vtbG2d_:hover {
  border: solid 2px #e6003c;
}

.input-checkbox-label-1z2vp22l {
  display: inline-block;
  font-size: 15px;
  font-weight: 400;
  color: var(--secondary-text-color);
  cursor: pointer;
  user-select: none;
}

.input-checkbox-checkmark-active-3FhwayAh {
  background-color: var(--tertiary-text-color);
  border: solid 2px var(--tertiary-text-color);
}

.theme-promo-sunrise .input-checkbox-checkmark-active-3FhwayAh {
  background-color: #e6003c;;
  border-color: #e6003c;
}


.formActions-1DLz_g38 {
  margin-bottom: 80px;
}

.submitBtn-K8H0LqaP {
  position: absolute;
}

