/* Transition duration */

button,
a {
  -webkit-transition: all 300ms;
  -moz-transition: all 300ms;
  -ms-transition: all 300ms;
  -o-transition: all 300ms;
  transition: all 300ms;
}

/* Fade in */

.fade-in {
  -webkit-animation: fadein-KQGdB3_q .7s;
  -moz-animation: fadein-KQGdB3_q .7s;
  -ms-animation: fadein-KQGdB3_q .7s;
  -o-animation: fadein-KQGdB3_q .7s;
  animation: fadein-KQGdB3_q .7s;
}

@keyframes fadein-KQGdB3_q {
  from { opacity: 0; }
  to   { opacity: 1; }
}

@-moz-keyframes fadein-KQGdB3_q {
  from { opacity: 0; }
  to   { opacity: 1; }
}

@-webkit-keyframes fadein-KQGdB3_q {
  from { opacity: 0; }
  to   { opacity: 1; }
}

@-ms-keyframes fadein-KQGdB3_q {
  from { opacity: 0; }
  to   { opacity: 1; }
}

@-o-keyframes fadein-KQGdB3_q {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Fade out */

.fade-out {
  -webkit-animation: fadeout-1J-9lsQw .7s;
  -moz-animation: fadeout-1J-9lsQw .7s;
  -ms-animation: fadeout-1J-9lsQw .7s;
  -o-animation: fadeout-1J-9lsQw .7s;
  animation: fadeout-1J-9lsQw .7s;
}

@keyframes fadeout-1J-9lsQw {
  from { opacity: 1; }
  to   { opacity: 0; }
}

@-moz-keyframes fadeout-1J-9lsQw {
  from { opacity: 1; }
  to   { opacity: 0; }
}

@-webkit-keyframes fadeout-1J-9lsQw {
  from { opacity: 1; }
  to   { opacity: 0; }
}

@-ms-keyframes fadeout-1J-9lsQw {
  from { opacity: 1; }
  to   { opacity: 0; }
}

@-o-keyframes fadeout-1J-9lsQw {
  from { opacity: 1; }
  to   { opacity: 0; }
}

/* Slide Down */

.slide-down {
  -webkit-animation: slideDown-2erbGAUQ .8s cubic-bezier(0.215, 0.61, 0.355, 1);
  -moz-animation: slideDown-2erbGAUQ .8s cubic-bezier(0.215, 0.61, 0.355, 1);
  -ms-animation: slideDown-2erbGAUQ .8s cubic-bezier(0.215, 0.61, 0.355, 1);
  -o-animation: slideDown-2erbGAUQ .8s cubic-bezier(0.215, 0.61, 0.355, 1);
  animation: slideDown-2erbGAUQ .8s cubic-bezier(0.215, 0.61, 0.355, 1);
}

@keyframes slideDown-2erbGAUQ {
  from { transform: translateY(-100px); }
  to  { transform: translateY(0); }
}

@-moz-keyframes slideDown-2erbGAUQ {
  from { -moz-transform: translateY(-100px); }
  to   { -moz-transform: translateY(0); }
}

@-webkit-keyframes slideDown-2erbGAUQ {
  from { -webkit-transform: translateY(-100px); }
  to   { -webkit-transform: translateY(0); }
}

@-ms-keyframes slideDown-2erbGAUQ {
  from { -ms-transform: translateY(-100px); }
  to   { -ms-transform: translateY(0); }
}

@-o-keyframes slideDown-2erbGAUQ {
  from { -o-transform: translateY(-100px); }
  to   { -o-transform: translateY(0); }
}

/* Spin */

.spin {
  -webkit-animation: spin-3i_vnsQK ease infinite 1.5s;
  -moz-animation: spin-3i_vnsQK ease infinite 1.5s;
  -ms-animation: spin-3i_vnsQK ease infinite 1.5s;
  -o-animation: spin-3i_vnsQK ease infinite 1.5s;
  animation: spin-3i_vnsQK ease infinite 1.5s;
  height: inherit;
  width: auto;
  transform-origin: 50% 50%;
  display: block;
  margin: 0 auto;
}

.spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 33px;
  width: 100%;
}

.inline-spinner {
  display: inline-block;
  height: 18px;
  margin: 9px 0 4px;
  width: auto;
}

@keyframes spin-3i_vnsQK {
  from { transform:rotate(0deg); }
  to   { transform:rotate(360deg); }
}

@-moz-keyframes spin-3i_vnsQK {
  from { transform:rotate(0deg); }
  to   { transform:rotate(360deg); }
}

@-webkit-keyframes spin-3i_vnsQK {
  from { transform:rotate(0deg); }
  to   { transform:rotate(360deg); }
}

@-ms-keyframes spin-3i_vnsQK {
  from { transform:rotate(0deg); }
  to   { transform:rotate(360deg); }
}

@-o-keyframes spin-3i_vnsQK {
  from { transform:rotate(0deg); }
  to   { transform:rotate(360deg); }
}

@keyframes gradient-1suDx2Kt {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
