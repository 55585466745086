form {
  padding: 0;
}

fieldset {
  padding: 0;
  border: 0;
}

legend {
  position: static;
  padding: 0;
  border: 0;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 1px;
  padding-top: 40px;
  padding-bottom: 28px;
  color: var(--primary-text-color);
  text-transform: uppercase;
}

.shop-checkout legend {
  color: #111111;
  font-weight: normal;
  font-size: 22px;
  line-height: 24px;
}


legend,
input,
select,
button {
  font-family: var(--primary-font-family);
}

/* Prevent chrome input background color for prefilled data */
input:-internal-autofill-previewed,
input:-internal-autofill-selected,
textarea:-internal-autofill-previewed,
textarea:-internal-autofill-selected,
select:-internal-autofill-previewed,
select:-internal-autofill-selected {
  background-color: white !important;
}

.form-row {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
}

.form-field {
  padding-left: 10px;
  margin-bottom: 28px;
}

.theme-promo-sunrise.checkout-page .form-field.form-field-phone {
  padding-bottom: 56px;
  margin-bottom: 0;
}

.shop-checkout .form-field {
   padding-left: 5px;
 }

@media(min-width: 993px) {
  .shop-checkout .form-field {
    margin-bottom: 30px;
  }
}

.form-field:first-child {
  padding-left: 0;
}

.form-row-items-1 .form-field {
  width: 100%;
}

.form-row-items-2 .form-field {
  width: 50%;
}

.form-row-address-appartment .form-field:first-child {
  width: 100%;
}

.form-row-address-appartment .form-field:nth-child(2) {
  width: 25%;
}

.form-row-city-state-post .form-field:first-child {
  width: 50%;
}

.form-row-city-state-post .form-field:nth-child(2),
.form-row-city-state-post .form-field:nth-child(3) {
  width: 25%;
}

.form-row-city-post .form-field:first-child {
  width: 75%;
}

.form-row-city-post .form-field:nth-child(2) {
  width: 25%;
}

.form-row-address-appartment .form-field:only-child {
  width: 100%;
}

.formFieldIsRequired .inputText {
  border-bottom: 2px solid var(--input-border-color);
}

.formFieldHasError .inputText {
  border-bottom: 2px solid var(--color-error);
}

.formFieldIsValid .inputText {
  border-bottom: 2px solid var(--color-success);
}

.form-actions {
  margin-bottom: 80px;
  position: relative;
  padding: 0;
  display: flex;
  justify-content: center;
}

/* :global(button.button-submit) {
  align-items: center;
  background-color: var(--button-background-color-primary);
  border: 0;
  border-radius: 28px;
  color: var(--button-text-color-primary);
  height: 56px;
  display: flex;
  justify-content: center;
  flex-direction: row;
  font-weight: 500;
  padding: 0 50px;
  cursor: pointer;
}

:global(button.buttonSubmit:disabled) {
  background-color: var(--button-background-color-disabled);
  cursor: default;
} */

.inputErrorMessage {
  color: var(--color-error);
  font-size: 12px;
  line-height: 16px;
  margin: 5px 0 0;
  position: absolute;
  width: 221px;
}

.theme-promo-belgium .inputErrorMessage {
  color: var(--color-error);
  font-size: 12px;
  line-height: 16px;
  margin: 5px 0 0;
  position: absolute;
  width: 100%;
}

.shop-checkout .inputErrorMessage  {
  font-size: 10px;
}

.labelOptional span {
  text-transform: lowercase;
}

@media(max-width: 992px) {
  legend {
    font-size: 16px;
    padding-top: 0;
    padding-bottom: 20px;
  }

  /* :global(.form-row) {
    flex-direction: column;
  } */

  .form-row-items-1 .form-field {
    width: 100%;
  }

  .form-row-items-2 .form-field {
    width: 100%;
    padding-left: 0;
  }

  .form-row-address-appartment .form-field:first-child {
    width: 100%;
  }

  .form-row-address-appartment .form-field:nth-child(2) {
    width: 100%;
    padding-left: 0;
  }

  .form-row-city-state-post {
    flex-direction: row;
    flex-wrap: wrap;
  }

  .form-row-city-state-post .form-field:first-child {
    width: 100%;
  }

  .form-row-city-state-post .form-field:nth-child(2) {
    width: 40%;
    padding-left: 0;
  }
  .form-row-city-state-post .form-field:nth-child(3) {
    width: 60%;
  }

  .form-row-city-post .form-field:first-child {
    width: 67%;
  }

  .form-row-city-post .form-field:nth-child(2) {
    width: 33%;
  }
  .shop-checkout legend {
    font-size: 20px;
    line-height: 28px;
  }
}
