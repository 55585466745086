.notDefault-26EATwG1 {
  padding-top: 26px;
}

.cardFadeOut-31GfEOr6 {
  opacity: 0;
}

.card-2ip3s_7E {
  width: 33.33%;
  padding-right: 20px;
  color: var(--color-darker-grey);
}

@media screen and (max-width: 760px) {
  .card-2ip3s_7E {
    width: 100%;
  }
}

@media screen and (max-width: 992px) {
  .card-2ip3s_7E {
    width: 50%;
  }
}
