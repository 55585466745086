.header-2kTQKWlG {
  border-bottom: 1px solid #EEEFF0;
}

.navigation-desktop-3dsCRKh2 {
  background: var(--navigation-background-color);
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  margin: 0 auto;
  height: 60px;
  z-index: 13;

}

.navigation-bg-hp {
  background: #eeeff0;
}
body[data-user-agent*="MacIntel"] .signLbl,
body[data-user-agent*="iPad"] .signLbl,
body[data-user-agent="iPhone"] .signLbl{
  position: relative;
  top: 2px;
}
body[data-user-agent*="Win32"] .signLbl {
  position: relative;
  top: -1px;
}

/* header  hamburger menu */

.header-trigger-1FpjRkb7 {
  display: none;
  width: 30px;
  height: 30px;
  position: relative;

  align-items: center;
  cursor: pointer;
}

.header-trigger-1FpjRkb7:before {
  content: '';
  position: absolute;
  background: #111111;
  height: 1px;
  width: 16px;
}

.header-trigger-1FpjRkb7 span {
  width: 16px;
  height: 11px;
  display: inline-block;
  position: relative;
}

.header-trigger-1FpjRkb7 span:before,
.header-trigger-1FpjRkb7 span:after {
  content: '';
  background: #111111;
  height: 1px;
  width: 100%;
  position: absolute;
  top: 0;
}

.header-trigger-1FpjRkb7 span:after {
  top: auto;
  bottom: 0;
}

.header-trigger-1FpjRkb7 img {
  display: none;
}

.mobile-nav-active .header-trigger-1FpjRkb7 {
  left: -2px;
  top: 1px;
}

.mobile-nav-active .header-trigger-1FpjRkb7 img {
  display: block;
  filter: invert(100%);
}

.mobile-nav-active .header-trigger-1FpjRkb7 span {
  width: 20px;
  height: 20px;
}

.mobile-nav-active .header-trigger-1FpjRkb7:before {
  opacity: 0;
}

.mobile-nav-active .header-trigger-1FpjRkb7 span:before,
.mobile-nav-active .header-trigger-1FpjRkb7 span:after {
  display: none;
}

@media only screen and (min-width: 768px) {
  .menu-indicator {
    position: relative;
    z-index: 0;
  }
  .menu-indicator .menu-item:last-child:before,
  .menu-indicator .menu-item:last-child:after {
    content: "";
    display: block;
    position: absolute;
    pointer-events: none;
    transition: left 0.5s ease, width 0.5s ease;
  }
  .menu-indicator .menu-item:last-child:after {
    /* temporary */
    display: none!important;
    top: 26px;
    left: 15.4%;
    width: 18%;
    z-index: -1;
    border: 1px solid var(--primary-text-color);
    border-radius: 15px;
    height: 28px;
  }
  /* navigation tabs */

  /* tab - adaptive wifi */
  .menu-indicator
      .menu-item:nth-child(1).active
      ~ .menu-item:last-child:after {
    left: 15.4% !important;
    width: 18% !important;
  }
  .menu-indicator
      .menu-item:nth-child(1).active
      ~ .menu-item:last-child:before {
    left: 10% !important;
  }

  /* tab - Ai security */
  .menu-indicator
      .menu-item:nth-child(2).active
      ~ .menu-item:last-child:after {
    width: 14.5% !important;
    left: 34.6% !important;
  }
  .menu-indicator
      .menu-item:nth-child(2).active
      ~ .menu-item:last-child:before {
    left: 39% !important;
  }

  /* tab - Homepass */
  .menu-indicator
      .menu-item:nth-child(3).active
      ~ .menu-item:last-child:after {
    left: 49.9% !important;
    width: 16% !important;
  }

  .menu-indicator
      .menu-item:nth-child(3).active
      ~ .menu-item:last-child:before {
    left: 50% !important;
  }

  /* tab - Wifi Motion */
  .menu-indicator .menu-item:last-child.active:before {
    left: 90% !important;
  }

  .menu-indicator .menu-item:last-child.active:after {
    left: 66.2% !important;
    width: 18.25% !important;
  }

  /* end navigation tabs */


}

@media only screen and (min-width: 993px) {
  .menu-indicator .menu-item:not(:last-child) {
    margin-right: 24px;
  }

  .menu-indicator .menu-item:last-child:after {
    top: 26px;
    left: 5.25%;
    width: 24%;
  }
  /* navigation tabs */

  /* tab - adaptive wifi */
  .menu-indicator
      .menu-item:nth-child(1).active
      ~ .menu-item:last-child:after {
    left: 5.25% !important;
    width: 24% !important;
  }
  .menu-indicator
      .menu-item:nth-child(1).active
      ~ .menu-item:last-child:before {
    left: 10% !important;
  }

  /* tab - Ai security */
  .menu-indicator
      .menu-item:nth-child(2).active
      ~ .menu-item:last-child:after {
    width: 17.7% !important;
    left: 30.5% !important;
  }
  .menu-indicator
      .menu-item:nth-child(2).active
      ~ .menu-item:last-child:before {
    left: 39% !important;
  }

  /* tab - Homepass */
  .menu-indicator
      .menu-item:nth-child(3).active
      ~ .menu-item:last-child:after {
    left: 51.20% !important;
    width: 18% !important;
  }

  .menu-indicator
      .menu-item:nth-child(3).active
      ~ .menu-item:last-child:before {
    left: 50% !important;
  }

  /* tab - Wifi Motion */
  .menu-indicator .menu-item:last-child.active:before {
    left: 90% !important;
  }

  .menu-indicator .menu-item:last-child.active:after {
    left: 71.8% !important;
    width: 21% !important;
  }


}


.navigation-desktop-checkout-fnEF420R .navigation-desktop-content-3MBHR7J8 {
  justify-content: space-between;
}

.navigation-desktop-content-3MBHR7J8 {
  position: relative;
  display: flex;
  justify-content: left;
  align-items: center;
  height: 100%;
}

.btn-shadow-26DTmjVf {
  box-shadow: -2px 1px 5px 0 rgba(98, 105, 255, 0.2);
  transition: all 0.7s cubic-bezier(0.215, 0.61, 0.355, 1);
  margin-left: 30px;
}

.primary-menu-3QQFrdlE {
  position: absolute;
  display: flex;
  list-style: none;
  left: 0;
  margin: 0;
  padding: 0;
}

.primary-menu-3QQFrdlE li {
  margin-right: 40px;
}

.primary-menu-3QQFrdlE li:last-child {
  margin-right: 0;
}

.primary-menu-3QQFrdlE li a {
  color: var(--navigation-text-color);
  font-size: 13px;
  font-weight: normal;
  text-transform: uppercase;
  letter-spacing: 1.1px;
  cursor: pointer;
}

.primary-menu-3QQFrdlE li a.active-sTgSXLBW {
  color: var(--tertiary-text-color);
}

.primary-menu-3QQFrdlE svg {
  margin-left: 5px;
  margin-bottom: 2px;
}

.primary-menu-3QQFrdlE li a.active-sTgSXLBW svg {
  margin-bottom: 0;
}

.extras-3to5cl10 {
  position: absolute;
  align-items: center;
  right: 0;
  display: flex;
}

.extras-3to5cl10 a + a {
  margin-left: 35px;
}

.navigation-desktop-back-1ljKwY3i {
  color: #3c396f;
  cursor: pointer;
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: 1.1px;
}

.navigation-sign-in-4wJBQ49S {
  display: block;
  padding: 0;
  font-size: 13px;
  color: var(--navigation-text-color);
  width: auto;
  cursor: pointer;
}
.navigation-sign-in-4wJBQ49S span {
  font-size: 16px;
  margin: 0px 10px 0 0;
  line-height: 1;
  vertical-align: middle;
}
.navigation-sign-in-4wJBQ49S svg {
  margin-right: 4px;
  vertical-align: middle;
  width: 20px;
}

.navigation-sign-in-4wJBQ49S:hover {
  text-decoration: none;
}
