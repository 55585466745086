.theme-promo-sunrise .sticky-header-stack {
  background-color: #fff;
}
.theme-promo-sunrise .accent-text-color,
.theme-promo-sunrise .accent-text-color strong,
.theme-promo-sunrise .accent-text-color .accent-svg-color ellipse{
  color: #e6003c !important;
  fill: #e6003c;
}
.theme-promo-sunrise .accent-bg-color {
  background: #e6003c !important;
  border-color: #e6003c !important;
}
.theme-promo-sunrise .accent-link-color,
.theme-promo-sunrise .accent-link-color strong {
  color: #95223d !important;
}
.theme-promo-sunrise .accent-hide {
  display: none;
}
.theme-promo-sunrise .accent-show {
  display: block;
}
.theme-promo-sunrise #userActions .drop-arrow svg path {
  stroke: #e6003c;
}
.theme-promo-sunrise #userActions {
  color: #e6003c;
}
.theme-promo-sunrise #userActions:hover {
  opacity: .75;
}
.theme-promo-sunrise .accent-svg-header svg path {
  fill: #e6003c;
}
.theme-promo-sunrise .accent-svg-header svg g circle {
  stroke: #e6003c;
}
/*}*/
.theme-promo-sunrise .linkContainer .accent-svg-color svg g {
  stroke: #e6003c;
}
/*}*/
.theme-promo-sunrise .accent-svg.accent-svg-color svg path {
  fill: #e6003c;
}
/*  fill: #e6003c;*/
.theme-promo-sunrise .accent-svg.accent-svg-color.disabled svg path {
  fill: #f27f9d;
}
/*.theme-promo-sunrise .accent-svg.accent-svg-color svg g {*/
.theme-promo-sunrise .accent-select-color {
  color: #e6003c !important;
}
/*  stroke: #e6003c;*/
.theme-promo-sunrise .accent-border-color {
  border-color: #e6003c !important;
}
/* Header */
.theme-promo-sunrise .btn-solid-dark {
  background: #e6003c;
  border-color: #e6003c;
}
/*.theme-promo-sunrise .accent-svg-color svg g {*/
.theme-promo-sunrise .btn-solid-dark:disabled {
  opacity:0.5;
}
/*Product Selector*/
.theme-promo-sunrise .selectListContainer .accent-select-color ul li.active a {
  color: #e6003c;
  font-weight: 500;
}
/* Header */
.theme-promo-sunrise .selectListContainer .accent-select-color ul li.active {
  background: #faccd8;
}
.theme-promo-sunrise .accent-sunrise-store {
  display:block
}
@media only screen and (min-width: 993px) {
  .theme-promo-sunrise #superPodAddon .media-container {
    min-height: 140px;
  }
}
@media(max-width: 992px) {
  .theme-promo-sunrise .membershiplink a,
  .theme-promo-sunrise #membership_button_addon{
    bottom: 0;
  }
  .theme-promo-sunrise #membership_button_addon {
    display: flex;
    align-items: center;
  }
  .cookie-banner-hidden .theme-promo-sunrise #membership_button {
    bottom: 0 !important;
  }
}
@media(max-width: 760px) {
  .theme-promo-sunrise .dropdown-qty {
     float:right;
  }

}
/*Product Configurator*/
.theme-promo-sunrise #configuratorPage .configurator .details-cart {
  min-height:100vh;
}
/*Product Selector*/
.theme-promo-sunrise .options .option-item .label input:checked + span:before {
  background: #e6003c !important;
}
.theme-promo-sunrise #configuratorPage #configurator-bundles .bundle-container.active a {
  color: #e6003c;
}
.theme-promo-sunrise .accent-svg.accent-svg-color svg.home-icon g {
  stroke: none;
}
.theme-promo-sunrise #configurator-bundles .membershipBtn .label {
  visibility: hidden;
}
@media(max-width: 992px) {
  .theme-promo-sunrise #configuratorPage #configurator-bundles  .bundle-title {
    display: none;
  }
  .theme-promo-sunrise #configuratorPage #configurator-bundles  .bundle-container {
    margin: 40px 0 0;
  }
}
@media(max-width: 760px) {
  .theme-promo-sunrise .dropdown-qty {
    float:right;
  }
  .theme-promo-sunrise .accent-hide-m {
    display: none;
  }
  .theme-promo-sunrise #configuratorPage #configurator-bundles  .bundle-container {
    margin: 20px 0 0;
  }
  .theme-promo-sunrise  #configuratorPage .mainContainer {
    padding:95px 0 60px 0;
  }
  body:not(.cookie-banner-hidden) .theme-promo-sunrise  #configuratorPage .mainContainer{
    padding-bottom: 255px;
  }
}
@media (max-width: 320px) {
  body:not(.cookie-banner-hidden) .theme-promo-sunrise  #configuratorPage .mainContainer{
    padding-bottom: 300px;
  }
}
@media only screen and (min-width: 993px) {
  .theme-promo-sunrise #configurator-bundles .bundle-title {
    padding: 5px 10px 15px 20px;
  }
}
@media only screen and (min-width: 1280px) {
  .theme-promo-sunrise #configurator-bundles .bundle-title {
    padding: 5px 40px 34px 75px;
  }
}
/* Checkout Page */
.theme-promo-sunrise #checkoutPage .accent-svg-path svg path {
  fill: none;
  stroke: #fff;
}
/*Product Configurator*/
.theme-promo-sunrise #checkoutPage .accent-svg-path svg rect {
  fill: #e6003c;
  stroke: #e6003c;
}
.theme-promo-sunrise #checkoutPage .accent-text-color-black {
  color: #2e2e2e;
}
.theme-promo-sunrise #checkoutPage .shipping-method-active .accent-text-color-black {
  color: #2e2e2e;
  text-transform: none;
}
.theme-promo-sunrise #billingFormToggler + .form-row.form-row-items-1 .form-field > div {
  margin-bottom: 0;
}
@media only screen and (min-width: 993px) {
  .theme-promo-sunrise.checkout-page  .accent-checkout-sidebar {
    top: 89px;
  }
  .theme-promo-sunrise .checkout-container-inner-new fieldset {
    margin-left: 0;
    margin-right: 0;
    padding-bottom: 23px;
  }
  .theme-promo-sunrise #billingFormToggler + .form-row.form-row-items-1 .form-field {
    margin-bottom: 0;
  }
  .theme-promo-sunrise #billingForm + #shipping .form-row.form-row-items-1:not(:first-child) .form-field {
    margin-bottom: 7px;
  }
  .theme-promo-sunrise #checkoutPage #sidebarCheckoutButton {
    margin-top: 15px;
  }
  .theme-promo-sunrise span[data-content=" (optional)"] {
    white-space: nowrap;
    height: 21px;
  }
}
@media(max-width: 992px) {
  .theme-promo-sunrise #checkoutPage .accent-checkoutHeading{
    top: 68px;
  }
  .order-summary-open .theme-promo-sunrise.checkout-page .accent-mbg-color {
    top: 115px;
  }
  .order-summary-open .theme-promo-sunrise.checkout-page.authorized .accent-mbg-color {
    top: 170px;
  }
  .theme-promo-sunrise.checkout-page .checkout-container-inner-new {
    padding: 175px 18px 150px;
  }
  .theme-promo-sunrise.checkout-page .accent-mbg-color{
    background: #444444;
  }
  .theme-promo-sunrise #billingForm + #shipping .form-row.form-row-items-1:first-child .form-field {
    margin-bottom: 40px;
  }
  .theme-promo-sunrise #billingFormToggler + .form-row.form-row-items-1 {
    clear: both;
  }
  .theme-promo-sunrise #billingFormToggler > div {
    margin-left: 30px;
  }
  .theme-promo-sunrise #billingFormToggler > div span:first-child {
    margin-left: -30px;
  }
}
@media(max-width: 767px) {
  .order-summary-open.authorized .theme-promo-sunrise.checkout-page .accent-mbg-color{
    top: 175px;
    margin: 0;
  }
}
@media (min-width: 768px) and (max-width: 992px) {
  .order-summary-open .theme-promo-sunrise.checkout-page.authorized .accent-mbg-color{
    top: 95px;
  }
}
@media (min-width: 400px) and (max-width: 766px) {
  .theme-promo-sunrise.authorized #checkoutPage .accent-checkoutHeading {
    top: 160px;
  }
  .order-summary-open .theme-promo-sunrise.checkout-page.authorized .accent-mbg-color{
    top: 155px;
  }
}
@media (max-width: 400px) {
  .theme-promo-sunrise #checkoutPage .accent-checkoutHeading{
    top: 95px;
  }
  .theme-promo-sunrise.authorized #checkoutPage .accent-checkoutHeading {
    top: 180px;
  }
}
.theme-promo-sunrise #checkoutPage .accent-checkoutHeading .accent-bg-color {
  background: #444444 !important;
  border-color: #444444 !important;
}
.theme-promo-sunrise #checkoutPage #sidebarCheckoutButton {
  background: #e6003c;
}
.theme-promo-sunrise #checkoutPage .shipping-method-active {
  border-color: #c6c6c6;
}
.theme-promo-sunrise #checkoutPage .shipping-method-active:after {
  border: solid 4px #e6003c;
}
.theme-promo-sunrise #checkoutPage form .form-field input,
.theme-promo-sunrise #checkoutPage form .form-field select {
  color: #2e2e2e;
}
.theme-promo-sunrise iframe .form-element input,
.theme-promo-sunrise iframe .form-element select {
  color:#2e2e2e;
}
.theme-promo-sunrise #sidebarPricing {
  background-color: rgba(230, 0, 60, 0.05);
}
.theme-promo-sunrise #sidebarFooter address {
  color: #939597;
}
.theme-promo-sunrise .invoice-page .checkout-container-new {
  padding-bottom:0;
}
.theme-promo-sunrise #invoicePage + .sticky-footer-stack {
  display: none;
}
/* Account Page */
.theme-promo-sunrise .dashboard-title h3,
.theme-promo-sunrise .dashboard-title h4,
.theme-promo-sunrise .dashboard-subtitle {
  color: #e6003c !important;
}
/* Checkout Page */
.theme-promo-sunrise .box label input:checked + span {
  background: #e6003c;
}
.theme-promo-sunrise .titleActionButton {
  color: #3C396F;
}
.theme-promo-sunrise .form-row input,
.theme-promo-sunrise .form-row select {
  color:#2e2e2e !important;
}
.theme-promo-sunrise .accent-svg-path svg path {
  fill: none;
  stroke: #fff;
}
.theme-promo-sunrise .accent-svg-path svg rect {
  fill: #e6003c;
  stroke: #e6003c;
}
