@font-face {
  font-family: Wigrum Web Thin;
  src: url("/static/fonts/WigrumWeb-Thin.eot");
  src: url("/static/fonts/WigrumWeb-Thin.eot?#iefix") format("embedded-opentype"),
       url("/static/fonts/WigrumWeb-Thin.woff") format("woff");
}

@font-face {
  font-family: Wigrum;
  src: url("/static/fonts/WigrumWeb-Light.eot");
  src: url("/static/fonts/WigrumWeb-Light.eot?#iefix") format("embedded-opentype"),
       url("/static/fonts/wigrumweb-light.woff") format("woff");
  font-style: normal;
  font-weight: 100;
}

@font-face {
  font-family: Wigrum;
  src: url("/static/fonts/WigrumWeb-Regular.eot");
  src: url("/static/fonts/WigrumWeb-Regular.eot?#iefix") format("embedded-opentype"),
       url("/static/fonts/wigrumweb-regular.woff") format("woff");
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: Wigrum;
  src: url("/static/fonts/WigrumWeb-Medium.eot");
  src: url("/static/fonts/WigrumWeb-Medium.eot?#iefix") format("embedded-opentype"),
       url("/static/fonts/wigrumweb-medium.woff") format("woff");
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: Wigrum;
  src: url("/static/fonts/WigrumWeb-Bold.eot");
  src: url("/static/fonts/WigrumWeb-Bold.eot?#iefix") format("embedded-opentype"),
       url("/static/fonts/wigrumweb-bold.woff") format("woff");
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: OC-Rey;
  src: url("/static/fonts/OC-Rey-Bold.otf") format("opentype");
  font-style: normal;
  font-weight: 700;
}



@font-face {
  font-family: OC-Rey;
  src: url("/static/fonts/OC-Rey-Medium.otf") format("opentype");
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: OC-Rey;
  src: url("/static/fonts/OC-Rey-Regular.otf") format("opentype");
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: OC-Rey;
  src: url("/static/fonts/OC-Rey-Light.otf") format("opentype");
  font-style: normal;
  font-weight: 100;
}