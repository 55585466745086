.legal-2aZceNnQ {
  background: white;
  padding-top: 100px;
  padding-left: 60px;
  padding-right: 60px;
  padding-bottom: 50px;
  min-height: 100%;
}

@media screen and (max-width: 1000px) {
  .legal-2aZceNnQ {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.legal-2aZceNnQ h1 {
  font-weight: 500;
  margin-bottom: 40px;
}

.legal-2aZceNnQ h3 {
  font-size: 20px;
  margin-bottom: 18px;
  font-weight: 500;
} 

.legal-2aZceNnQ ol,
.legal-2aZceNnQ ul {
  margin-left: 20px;
}

.legal-2aZceNnQ ol li {
  list-style: decimal inside;
}

.legal-2aZceNnQ ul li {
  list-style: square inside;
}

.menu-2lA3fWws {
  border-bottom: 1px solid #c2c2c2;
  overflow-y: auto;
  margin-left: 0 !important;
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 760px) {
  .menu-2lA3fWws {
    display: block;
  }

  .menu-2lA3fWws > li {
    display: block;
    width: 100%;
    text-align: left !important;
  }

  .menu-2lA3fWws > li a {
    text-align: left;
    padding-bottom: 10px;
    display: block;
  }
}

.menu-2lA3fWws > li {
  list-style: none !important;
  text-align: center;
  max-width: 160px;
  padding-bottom: 5px;
}

.menu-2lA3fWws a {
  text-decoration: none;
  color: black;
  opacity: 0.45;
}

.menu-2lA3fWws .active-32POWx-C {
  opacity: 1;
}



.legalpageRHS-2MFfxyEn {
  background: #fff;
}




@media only screen and (max-width: 960px) {

  .legal-2aZceNnQ {
    padding: 20px;
    padding-top: 65px;
  }
}

.LegalTabContent-1rym1Ick p {
  margin: 18px 0 24px 0;
}

.LegalTabContent-1rym1Ick ul li {
  margin: 24px 0;
  text-indent: -20px;
}

.LegalTabContent-1rym1Ick ol li {
  margin: 24px 0;
  text-indent: -18px;
}

.LegalTabContent-1rym1Ick ol li p {
  margin: 24px 0;
  text-indent: 0px;
}

.regionSwitcher-1HwOg9ir {
  position: relative;
  width: 38px;
  height: 16px;
  display: inline-block;
  vertical-align: middle;
  margin-left: 6px;
  opacity: 0.4;
  transition: opacity 0.2s;
  cursor: pointer;
}

.regionSwitcher-1HwOg9ir:hover {
  opacity: 1;
  transition: opacity 0.4s;
}

.regionSwitcher_select-mbn3prxO {
  position: absolute;
  text-transform: uppercase;
  background-color: transparent;
  border: none;
  top: 0;
  left: 0;
  width: 100%;
  display: inline-block;
  padding: 0;
  margin: 0;
  background-image: url(/arrow-down.svg);
  background-repeat: no-repeat;
  background-position: top 3px right;
  background-size: 17px 5px;
  -webkit-appearance: none;
  appearance: none;
  font-size: 16px;
  line-height: 1;
  cursor: pointer;
}

.LiSection-u5tLN0Bg {
  position: relative;
  padding-left: 6%;
}

.LiSection__Letter-M5xrTaBi {
  position: absolute;
  top: 0;
  left: 1.5%;
}
