.header-mini-1wHA_XWw {
  position: fixed;
  height: 60px;
  width: 100%;
  max-width: 1440px;
  z-index: 20;
  margin: 0 auto;
  top: 0;
  left: 0;
  right: 0;
  background: var(--color-white);
}
.sticky-3ngzkH2F {
  position: initial;
}

.cartButtonContainer-3i3i_GV0 {
  width: auto;
  margin-right: 24px;
  min-width: 135px;
}

@media(max-width: 480px) {
  .cartButtonContainer-3i3i_GV0 {
    margin-right: 14px;
  }
}

.cart-2jRMCh04 {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  position: relative;
  outline: none;
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  padding-right: 7px;
  padding-left: 2rem;
  text-align: left;
  margin: 0;
  background: #6269FF;
  color: #ffffff;
  height: 30px;
  line-height: 30px;
  cursor: pointer;
  transition: background 250ms ease-in-out, transform 150ms ease;
  -webkit-appearance: none;
  border-radius: 108px;
  -moz-appearance: none;
  letter-spacing: 2px;
  border: 1px solid #6269FF;
  width: 100%;
}

.theme-promo-belgium .cart-2jRMCh04 {
  padding-right: 10px;
}

.cart-2jRMCh04:before {
  content: "";
  width: 14px;
  height: 15px;
  background-image: url('/static/images/cart-icon-white.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 14px 15px;
  top: 5px;
  left: 11px;
  position: absolute;
  display: inline-block;
}

.cartHover-1R7JTkZz {
  background: white;
  color: #6269FF;
}

.cartHover-1R7JTkZz:before {
  content: "";
  width: 14px;
  height: 15px;
  background-image: url('/static/images/cart-icon-blue.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 14px 15px;
  top: 5px;
  left: 11px;
  position: absolute;
  display: inline-block;
}


.sticky-active .header {
  background: var(--color-white);
}

.header-mini-1wHA_XWw {
  -webkit-animation: removeSticky-2pGsL2z0 .3s;
  -webkit-animation-direction: removeSticky;
  animation: removeSticky-2pGsL2z0 .3s;
  animation-direction: removeSticky;
}
@keyframes removeSticky-2pGsL2z0 {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.header-trigger-1davAxyF {
  display: none;
  width: 30px;
  height: 30px;
  position: relative;

  align-items: center;
  cursor: pointer;
}

.header-trigger-1davAxyF:before {
  content: '';
  position: absolute;
  background: #111111;
  height: 1px;
  width: 16px;
}

.header-trigger-1davAxyF span {
  width: 16px;
  height: 11px;
  display: inline-block;
  position: relative;
}

.header-trigger-1davAxyF span:before,
.header-trigger-1davAxyF span:after {
  content: '';
  background: #111111;
  height: 1px;
  width: 100%;
  position: absolute;
  top: 0;
}

.header-trigger-1davAxyF span:after {
  top: auto;
  bottom: 0;
}

.header-trigger-1davAxyF img {
  display: none;
}

.mobile-nav-active .header-trigger-1davAxyF {
  left: -2px;
  top: 1px;
}

.mobile-nav-active .header-trigger-1davAxyF img {
  display: block;
  filter: invert(100%);
}

.mobile-nav-active .header-trigger-1davAxyF span {
  width: 20px;
  height: 20px;
}

.mobile-nav-active .header-trigger-1davAxyF:before {
  opacity: 0;
}

.mobile-nav-active .header-trigger-1davAxyF span:before,
.mobile-nav-active .header-trigger-1davAxyF span:after {
  display: none;
}

.header-trigger-container-1tJmlUzB {
  display: none;
}

@media (max-width: 767px) {
  .header-trigger-container-1tJmlUzB {
    display: none !important;
  }
  .header-trigger-1davAxyF{
    display: flex;
  }
}

.set-sticky-2GHlqD4V {
  -webkit-animation: stickyAnimate-2VoOjWXa 0.5s;
  -webkit-animation-direction: stickyAnimate;
  animation: stickyAnimate-2VoOjWXa 0.5s;
  animation-direction: stickyAnimate;
}
@keyframes stickyAnimate-2VoOjWXa {
  0%   {opacity: 0;}
  100% {opacity: 1;}
}

.content-QOPaxfn- {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2px 14px 0 8px;
  height: 100%;
  max-width: 1020px;
  margin: 0 auto;
}

.navigation-1ls-uFef {
  display: flex;
}
.brand-4GO2YOhF,
.brand-4GO2YOhF a {
  cursor: pointer;
}

.column-3lGrYYgp {
  border: 1px solid transparent;
  display: flex;
  align-items: center;
  margin-right: 10px;
  padding: 1px 0;
  cursor: pointer;
}

.column-3lGrYYgp:last-child {
  margin-right: 0;
}

.column-3lGrYYgp:hover{
  text-decoration: underline;
  text-decoration-color: #111111;
}

.active-menu {
  border-radius: 15px;
}

.sign-up-btn-16gkshb7 {
  border-radius: 14px;
  padding: 1px 16px 1px 16px;
  border: 1px solid #6269FF;
  min-height: 28px;
  margin-bottom: -3px;
}

.header-mini-text-2xyQdU-d {
  color: var(--header-links-color);
  font-size: 13px;
}

@media(max-width: 992px) {
  .header-mini-1wHA_XWw {
    border-bottom: 1px solid rgba(0,0,0,.1);
    height: 61px;
  }
}

@media(max-width: 767px) {
  .navigation-1ls-uFef{
    flex-direction: column;
  }
  .mobile-nav-active .navigation-1ls-uFef{
    display: flex;
    justify-content: center;
    width: 100%;
    position: absolute;
  }

  .navigation-1ls-uFef {
    display: none;
    background: var(--secondary-background-color);
    height: auto;
    padding-bottom: 16px;
    padding-top: 16px;
    border-bottom: 1px solid #EEEFF0;;
    top: 60px;
    left: 0;
    transition: none;
    transform: translateY(0);
    max-width: 100%;
  }
  .column-3lGrYYgp {
    margin-left: 5px;
    margin-right: 5px;
    padding: 5px 14px;
    justify-content: center;
    border: none!important;
  }
  .stroke-path {
    -webkit-transform: translate3d(1px, 1px, 0);
    transform: translate3d(1px, 1px, 0);
    opacity: 0.7;
  }
  .column-3lGrYYgp.active .stroke-path {
    stroke-width: 2px;
    opacity: 1;
  }
  #header-homePass-link.active.column-3lGrYYgp .stroke-path path {
    stroke-width: 1px;
  }

  #header-wifiMotion-link.active .fill {
    opacity: 1 !important;
  }

}
@media(min-width: 768px) {

  .set-sticky-2GHlqD4V .header-mini-text-2xyQdU-d {
    opacity: 1;
    transition: all .5s;
  }
  .header-mini-text-2xyQdU-d {
    opacity: 0;
    transition: all .5s;
  }
  body[data-user-agent*="MacIntel"] .regular.nav-links,
  body[data-user-agent*="iPad"] .regular.nav-links {
    position: relative;
    top: 2px;
  }
  .column.active-menu{
    -webkit-animation: slide-right-2XrnPjPW 0.5s 2s both;
    animation: slide-right-2XrnPjPW 0.5s 2s both;
  }
  @keyframes slide-right-2XrnPjPW {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
    100% {
      -webkit-transform: translateX(100px);
              transform: translateX(100px);
    }
  }

}

@media(max-width: 1250px) {

  #header .container {
    padding: 0;
  }
}

@media(max-width: 1150px) {
  #header .container {
    padding: 0;
  }
}

.no-border-2dZs6z8V {
  border-bottom: 1px solid #fff;
}
