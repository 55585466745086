.btn {
    display: flex;
    justify-content: center;
    line-height: 1.1;
    border-radius: 28px;
    border: 1px solid;
    font-weight: 500;
    cursor: pointer;
    text-decoration: none;
    font-family: var(--primary-font-family);
    width: 100%;
}

.btn-inline-flex {
    display: inline-flex;
}

.btn-one-line {
    width: auto;
    max-width: auto;
    padding: 1.1em 2em;
    white-space: nowrap;
}

.btn-small {
    font-size: 14px;
    padding: 16px 30px 15px;
    max-width: 140px;
}

.btn-medium {
    font-size: 16px;
    padding: 16px 30px 15px;
    max-width: 300px;
}

.btn-large {
    font-size: 18px;
    padding: 15px 30px 14px;
    max-width: 235px;
}

.btn-solid-light {
    color: var(--button-light-text-color);
    background-color: var(--button-light-background-color);
    border-color: var(--button-light-background-color);
}

.btn-solid-light:hover,
.btn-solid-light:focus,
.btn-solid-light:active {
    opacity: .9;
}

.btn-solid-light:disabled {
    background-color: var(--button-background-color-disabled);
    cursor: default;
}

.btn-solid-dark {
    color: var(--button-dark-text-color);
    background-color: var(--button-dark-background-color);
    border-color: var(--button-dark-background-color);
}

.btn-solid-dark:hover,
.btn-solid-dark:focus,
.btn-solid-dark:active {
    opacity: .9;
}

.btn-solid-dark:disabled {
    background-color: var(--button-dark-background-disabled-color);
    border-color: var(--button-dark-background-disabled-color);
    cursor: default;
}

.btn-outline {
    color: var(--button-outline-text-color);
    background-color: var(--button-outline-background-color);
    border-color: var(--button-outline-border-color);
}

.btn-outline:hover,
.btn-outline:focus,
.btn-outline:active {
    opacity: .9;
}

.btn-outline:disabled {
    background-color: var(--button-background-color-disabled);
    cursor: default;
}

.btn strike {
  text-decoration: none;
  display: inline-block;
  position: relative;
}

.btn strike:after {
  content: '';
  position: absolute;
  left: -2px;
  right: -2px;
  height: 2px;
  background-color: #FA1478;
  opacity: 0.7;
  top: 50%;
  margin-top: -3px;
}

