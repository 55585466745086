* {
  box-sizing: border-box;
  word-wrap: break-word;
}

/**
 * 1. Correct the line height in all browsers.
 * 2. Prevent adjustments of font size after orientation changes in iOS.
 */

html {
  line-height: 1.15; /* 1 */
  -webkit-text-size-adjust: 100%; /* 2 */
}

html,
body,
body #__next-2A7a2vEe {
  width: 100%;
  height: 100%;
}

body {
  font-style: normal;
  font-stretch: normal;
  font-family: var(--primary-font-family);
  background-color: var(--secondary-background-color);
  letter-spacing: normal;
  -webkit-overflow-scrolling: touch;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-overflow-scrolling: touch;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  -webkit-text-size-adjust: none;
  margin: 0;
  padding: 0;
}
body[data-user-agent*="MacIntel"] .signLbl,
body[data-user-agent*="iPad"] .signLbl {
  position: relative;
  top: 2px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

p {
  margin: 0;
}

*:focus,
*:active {
  outline: 0 !important;
}

a {
  text-decoration: none;
}

.layout {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding-top: 60px;
  background-color: transparent;
}

.isp-layout {
  padding-top: 0!important;
}

.layout.theme-promo-sunrise.invoice-page {
  padding-top: 80px;
}

.shop-bg-white {
  background-color: white;
}

 {

/*.shop-checkout .checkout-container-new,*/
/*.shop-checkout .checkout-container-inner-new .checkout-container-inner-new-with-margin {*/
/*  margin-right: 130px;*/
}

.shop-checkout .checkout-container-new,
.shop-checkout .checkout-container-inner-new {
  background: white;
}

/*}*/

.shipping-content {
  max-width: 447px;
  }

@media (max-width: 992px) {
  .layout {
       padding-top: 60px;
     }
  .layout.theme-promo-sunrise {
    padding-top: 95px;
  }
    .shop-checkout .checkout-container-new,
    .shop-checkout .checkout-container-inner-new .checkout-container-inner-new-with-margin {
      margin-right: 0;
    }
}

#header-mini .container {
  width: 100%;
  height: 100%;
  padding: 0 60px;
}

.container {
  width: 100%;
  height: 100%;
  padding: 0 60px;
}

#header .container {
  padding: 0;
}

@media (max-width: 992px) {
  .container {
    padding: 0 30px;
  }

  #header .container {
    padding: 0;
  }
}

.checkout-container { /* TODO cleanup */
  overflow-x: hidden;
  position: relative;
  display: flex;
  align-content: stretch;
  flex-flow: row nowrap;
  min-height: calc(100vh - 80px);
  background: white;
}

.checkout-container-new {
  overflow: hidden;
  position: relative;
  display: flex;
  align-content: stretch;
  flex-flow: row nowrap;
  min-height: calc(100vh - 80px);
  background: #fff;
}

.checkout-container-inner-new {
  background: #fff;
  padding: 20px;
  width: 100%;
}
@media (min-width: 993px) {
  .checkout-container-inner-new {
    max-width: 586px;
    padding-left: 0;
    padding-right: 0;
    flex-basis: 49.5%;
    margin-right: 115px;
  }
  body:not(.cookie-banner-hidden) .checkout-container-inner-new {
    padding-bottom: 150px;
  }
  .theme-promo-sunrise .checkout-container-inner-new {
    flex-basis: 58%;
    max-width: 580px;
  }
}

.checkout-container-inner-new fieldset legend {
  position: absolute;
  padding: 0;
  margin: 0;
}

.checkout-container-inner-new fieldset {
  background-color: #fff;
  padding: 20px;
  border-radius: 15px;
  position: relative;
  box-shadow: 0 1px 3px 0 #dbdbdb;
  margin-bottom: 20px;
}

.shop-checkout .checkout-container-inner-new fieldset {
  background-color: #fff;
  border-radius: 0;
  box-shadow: none;
  margin-bottom: 0;
}

@media (max-width: 992px) {
  .checkout-container {
    flex-direction: column;
    min-height: calc(100vh - 80px);
  }
}

@media (max-width: 992px) {  /* TODO cleanup */
  .checkout-container-new {
    flex-direction: column;
    min-height: calc(100vh - 80px);
    align-items: center;
  }
}

.default-container {
  position: relative;
  background: white;
  min-height: calc(100vh - 80px);
}

@media (max-width: 992px) {
  .default-container {
    min-height: calc(100vh - 80px);
  }
}

.overlay-video {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

/* Legal page styles. To be refactored */

.legal-page {
  font-size: 16px;
  line-height: 1.4;
  color: #111111;
}

.legal-page h2 {
  font-size: 27px;
  font-weight: 400;
  margin-bottom: 14px;
  color: #111111;
}


.legal-page h3 {
  font-size: 19px;
  font-weight: 500;
  color: #6269FF;
  letter-spacing: 1.5px;
  text-transform: uppercase;
}

.legal-page h3 a:target::before {
  content: "";
  display: block;
  height: 75px;
  margin-top: -80px;
}

.legal-page h4 {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 18px;
  font-weight: 500;
  color: #111111;
}

.legal-page h5 {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 18px;
  font-weight: 500;
}

.legal-page p,
.legal-page address {
  margin-bottom: 40px;
}

.legal-page a {
  color: #111111;
}

.legal-page ol,
.legal-page ul {
  padding: 0;
}

.legal-page .legal-page-content ol {
  padding-left: 25px;
}

.legal-page table {
  margin-bottom: 20px;
  border-collapse: collapse;
  border: 1px solid var(--secondary-text-color);
}

.legal-page tr {
  border-collapse: collapse;
  border: 1px solid var(--secondary-text-color);
}

.legal-page th,
.legal-page td {
  border-collapse: collapse;
  border: 1px solid var(--secondary-text-color);
  padding: 1ex;
  text-align: center;
}

.affirm-sandbox-container {
  width: calc(100vw + 18px);
  bottom: -18px !important;
}

@media (min-width: 992px) {
  .hide-desktop {
    display: none!important;
  }
}

@media (min-width: 768px) {
  .hide-desktop-small {
    display: none!important;
  }
}

@media (max-width: 991px) {
  .hide-mobile {
    display: none!important;
  }
}

@media (min-width: 768px) {
  .legal-page {
    display: table;
    padding: 0 0;
    width: 100%;
  }

}

@media (max-width: 992px) {
  .sidebarWrapper {
    width: 100%;
  }
  .invoice-page .checkout-container-inner-new {
    padding: 20px 18px 0;
    background-color: #fff;
  }
  .theme-promo-sunrise.invoice-page .checkout-container-inner-new {
    padding-top: 55px;
  }
  .order-summary-open body {
    position: relative;
    height: 100%;
  }
  .checkout-page.layout {
    padding-top: 0;
  }
  .theme-promo-sunrise.checkout-page.layout {
    padding-top: 140px;
  }
  .checkout-page .checkout-container-new {
    margin-top: -200px;
    padding-top: 200px;
  }
  .checkout-page .checkout-container-inner-new {
    -webkit-transition: visibility 0.5s ease;
    transition: visibility 0.5s ease;
    visibility: visible;
  }
  .order-summary-open .checkout-page .checkout-container-inner-new {
    overflow: hidden;
    max-height: calc(100vh - 60px);
    visibility: hidden;
  }
}

@media (min-width: 993px) {
  .sidebarWrapper {
    margin-top: 80px;
    max-width: 477px;
    flex-basis: 41%;
  }
  .checkout-container-new {
    max-width: 1218px;
    margin: 0 auto;
    justify-content: flex-end;
    padding-left: 20px;
    padding-right: 20px;
  }
  .theme-promo-sunrise .checkout-container-new {
    justify-content: flex-start;
  }
  .checkout-container-inner-new fieldset {
    padding: 40px 38px 5px;
  }
  .shop-checkout .checkout-container-inner-new fieldset {
    padding: 20px 0 5px;
  }
  .checkout-container-inner-new fieldset legend + .form-row {
    margin-top: 42px;
  }
  .shop-checkout .checkout-container-inner-new fieldset legend + .form-row {
    margin-top: 20px;
  }
  .checkout-container-inner-new fieldset legend + .form-row.deliver-to {
    margin-top: 48px;
  }
  .shop-checkout .checkout-container-inner-new fieldset legend + .form-row.deliver-to {
    margin-top: 20px;
  }
  .invoice-page .checkout-container-new {
    padding-bottom: 60px;
  }
  .theme-promo-sunrise.invoice-page .checkout-container-new {
    padding-bottom: 0;
  }
}

.theme-promo-sunrise .sticky-footer-stack #stickyCookie {
  z-index: 20;
  position: fixed;
  max-width: 1440px;
}

.sticky-header-stack {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 13;
}

@media (max-width: 500px) {
  .theme-promo-sunrise:not(.checkout-page) .sticky-header-stack {
    position: absolute;
  }
}

.layout #header {
  position: static;
}

.accent-link-color,
  .accent-link-color strong {
    color: var(--tertiary-text-color);
  }

table.chart-table {}

table.chart-table tr {}

table.chart-table tr td {
    width: 120px;
  }

table.chart-table tr td:nth-of-type(1) {
    text-align: left;
    width: auto;
  }

@media (max-width: 767px){
    table.chart-table {
      border: none;
    }
    table.chart-table thead {
      display: none;
    }
    table.chart-table tbody {
      display: block;
    }
    table.chart-table tr {
      display: block;
      margin-top: -1px;
    }
    table.chart-table tr td {
      width: auto;
      display: inline-block;
      border: none
    }
    table.chart-table tr td:nth-of-type(1) {
      text-align: left;
      width: auto;
      display: table-cell;
    }
}
