.modal-bg-OcP4eDqR {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(25, 25, 65, 0.5);
  z-index: 100;
}

.modal {
  position: fixed;
  background-color: #fff;
  box-shadow: 0 10px 40px 0 rgba(60,57,111,0.2);
  border-radius: 10px;
  top: 50%;
  left: 50%;
  width: 100%;
  max-width: 600px;
  height: auto;
  transform: translate(-50%, -50%);
  z-index: 101;
}

.modal-content-20-QMjJ3 {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 10px;
  min-height: 250px;
  padding-top: 76px;
  padding-bottom: 140px;
  padding-left: 70px;
  padding-right: 70px;
}

.magic-link-sent .modal-content-20-QMjJ3 {
  padding-bottom: 188px;
}

@media (max-width: 992px) {
  .modal-content-20-QMjJ3 {
    padding: 67px 22px 100px;
  }

  .magic-link-sent .modal-content-20-QMjJ3 {
    padding-bottom: 141px;
  }
}

.modal-close-3KQN_DYw {
  position: fixed;
  bottom: 60px;
  left: 50%;
  transform: translate(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  height: 50px;
  width: 50px;
  border: 0 none;
  border-radius: 100%;
  cursor: pointer;
  z-index: 1;
  padding: 0;
  overflow: hidden;
}
.magic-link-sent .modal-close-3KQN_DYw {
  bottom: 40px;
}

.modalCloseIcon-1qZKSiVk {
  position: absolute;
  left: 50%;
  bottom: 40px;
  transform: translate(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--secondary-text-color);
  background-color: var(--primary-background-color);
  height: 50px;
  width: 50px;
  border-radius: 100%;
  padding: 0;
  margin-top: 30px;
  cursor: pointer;
}

.modalCloseIcon-1qZKSiVk:hover {
  border: 1px solid var(--primary-text-color);
  background-color: var(--primary-text-color);
}

.modalCloseIconImage-9SGrlJFO {
  stroke: var(--secondary-text-color);
}

.modal.magic-link-sent .modalCloseIcon-1qZKSiVk {
  bottom: 75px;
}

.modal-spinner-3N2mi4o3 {
  width: 50px;
  height: 50px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.slide-up-2kDZWvdu {
  -webkit-animation: slideUp-3Qus0GL6 .6s cubic-bezier(0.215, 0.61, 0.355, 1);
  -moz-animation: slideUp-3Qus0GL6 .6s cubic-bezier(0.215, 0.61, 0.355, 1);
  -ms-animation: slideUp-3Qus0GL6 .6s cubic-bezier(0.215, 0.61, 0.355, 1);
  -o-animation: slideUp-3Qus0GL6 .6s cubic-bezier(0.215, 0.61, 0.355, 1);
  animation: slideUp-3Qus0GL6 .6s cubic-bezier(0.215, 0.61, 0.355, 1);
}

@keyframes slideUp-3Qus0GL6 {
  from { transform: translate(-50%, 100px); }
  to  { transform: translate(-50%, 0); }
}

@-moz-keyframes slideUp-3Qus0GL6 {
  from { -moz-transform: translate(-50%, 100px); }
  to   { -moz-transform: translate(-50%, 0px); }
}

@-webkit-keyframes slideUp-3Qus0GL6 {
  from { -webkit-transform: translate(-50%, 100px); }
  to   { -webkit-transform: translate(-50%, 0px); }
}

@-ms-keyframes slideUp-3Qus0GL6 {
  from { -ms-transform: translate(-50%, 100px); }
  to   { -ms-transform: translate(-50%, 0px); }
}

@-o-keyframes slideUp-3Qus0GL6 {
  from { -o-transform: translate(-50%, 100px); }
  to   { -o-transform: translate(-50%, 0px); }
}
@media only screen and (max-width:760px) {
  .modal{
    width: 95%;
  }
}
@media only screen and (max-width:992px) {
  .close-button {
    bottom:35px;
  }
  .modal {
    max-width: 335px;
    border-radius: 0;
  }
  .modal-content-20-QMjJ3 {
    border-radius: 0;
  }
  .magic-link-sent .modal-close-3KQN_DYw {
    bottom: 45px;
  }
}
