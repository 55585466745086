
/* === Common === */
.sunriseFooter-1YG3-RwQ {
  background: #95223d;
  color: #fff;
  float: left;
  width: 100%;
}
.footerSocial-1ilfFmQu ul li {
  list-style: none;
  display: inline-block;
  margin: 0 23px 0 0;
}
.footerSocial-1ilfFmQu ul {
  margin: 0;
  padding: 0;
  text-align: right;
}
.footerSocial-1ilfFmQu ul li:last-child {
  margin: 0;
}
.footerSocial-1ilfFmQu ul li svg {
  height: 32px;
  width: 32px;
}
.footerSocial-1ilfFmQu ul li a {
  display: block;
}
.footerLegal-1ERfyI0g {
  color: #fff;
  text-decoration: none;
}
.footerWrapper-2RJAS3ex {
  float: left;
  position: relative;
  width: 100%;
}
.footerLogos-2Pf6QiCD a {
  display: inline-block;
  vertical-align: middle;
}
.footerLogoSunrise-2SZc6bx7 {
  height: 20px;
  width: 90px;
  pointer-events: none;
}
.footerLogoPlume-39VyUjdp {
  height: 30px;
  width: 107px;
  pointer-events: none;
}
.sunriseTrademarks-MI-1X5yt h3 {
  font-size: 21px;
  font-weight: 500;
}
.footerCopyright-EiX_1j7G p {
  margin: 0;
}
.theme-promo-sunrise #stickyCookie {
  float: left;
  width: 100%;
  max-width: 100%;
  z-index: 5;
}
.theme-promo-sunrise #stickyStack {
  position: static;
  z-index: 5;
}

/* === Mobile small === */
@media only screen and (max-width: 374px)  {
  .sunrise-footer-icons-3XOq1Flg li {
    margin-right: 15px;
  }
}

/* === Mobile === */
@media only screen and (max-width: 767px) {
  .footerContainer---QI05UB {
    float: left;
    padding: 0 17px 0 16px;
    width: 100%;
  }
  .sunriseFooter-1YG3-RwQ {
    padding: 45px 0 56px;
  }
  body:not(.cookie-banner-hidden) .sunriseFooter-1YG3-RwQ {
    padding-bottom: 300px;
  }
  .sunriseTrademarks-MI-1X5yt h3 {
    margin: 20px 0 0 0;
    padding: 0;
  }
  .sunriseTrademarks-MI-1X5yt p {
    margin: 0;
    padding: 4px 0 23px;
    line-height: 18px;
  }
  .footerLegal-1ERfyI0g {
    vertical-align: middle;
  }
  .footerSocial-1ilfFmQu {
    float: right;
  }
  .footerRHS-3657U4O2 {
    padding: 0 0 34px;
  }
  .footerRHS-3657U4O2, .footerLHS-1az6X-EZ {
    float: right;
    width: 100%;
  }
  .sunriseTrademarks-MI-1X5yt {
    padding: 22px 0 0;
  }
  .footerLogoSunrise-2SZc6bx7 {
    margin: 0 18px 0 0;
  }
  .footerLegal-1ERfyI0g {
    float: left;
    width: 50%;
  }
}

@media only screen and (max-width: 500px)  {
  body:not(.cookie-banner-hidden) .sunriseFooter-1YG3-RwQ {
    padding-bottom: 420px;
  }
}

/* === desktop === */
@media only screen and (min-width: 768px) {
  .footerContainer---QI05UB {
    display: block;
    margin: 0 auto;
    max-width: 966px;
    width: 100%;
  }
  .sunriseFooter-1YG3-RwQ {
    padding: 60px 0 60px;
  }
  body:not(.cookie-banner-hidden) .sunriseFooter-1YG3-RwQ {
    padding-bottom: 230px;
  }
  .footerWrapper-2RJAS3ex {
    padding: 0 15px;
  }
  .footerRHS-3657U4O2 {
    text-align: right;
    float: right;
    width: 49%;
  }
  .footerLHS-1az6X-EZ {
    float: left;
    width: 51%;
  }
  .footerSocial-1ilfFmQu {
    position: absolute;
    right: 15px;
    bottom: 15px;
  }
  .sunriseTrademarks-MI-1X5yt h3 {
    margin: 27px 0 4px 0;
    padding: 0
  }
  .sunriseTrademarks-MI-1X5yt p {
    line-height: 19px;
    margin: 0 0 11px;
    padding: 0;
  }
  .footerLogoSunrise-2SZc6bx7 {
    margin: 0 30px 0 0;
  }
  .footerCopyright-EiX_1j7G {
    padding: 17px 0 0 0;
  }
}

