:root {
  /* Fonts */
  --primary-font-family: Wigrum, sans-serif;
  --secondary-font-family: Wigrum, sans-serif;
  --primary-font-family-thin: Wigrum Web Thin;
  /* Colors */
  --body-background-color: #f5f5f7;
  --primary-text-color: #3c396f;
  --primary-text-color-dark: #111111;
  --primary-text-color-dark-hovered: #0c0c0c;
  --primary-background-color: #fafafc;
  --sunrise-primary-text-color: #e6003c;
  --secondary-text-color: #939597;
  --sedondary-underline-color: #E5E5E5;
  --secondary-text-color-s: #777878;
  --secondary-background-color: #fff;
  --dim-background-color: rgba(25, 25, 65, 0.5);;
  --tertiary-text-color: #6269ff;
  --tertiary-text-color-hover: #4951ff;
  --highlight-color: #6269ff;
  --hero-text-color: #fff;
  --list-active-color: #d8d7e2;
  --navigation-text-color: #3c396f;
  --navigation-background-color: #fff;
  --navigation-dropdown-background-color: #fbfcfd;
  --ui-light-gray: #dbdbdb;
  --footer-primary-text-color: #939597;
  --secondary-text-color-dark: #A87793;
  --footer-secondary-text-color: #dbdbdb;
  --footer-background-color: #111111;
  --input-border-color: #ccc;
  --input-text-color: #1f1f1f;
  --input-background-color: #fff;
  --input-background-color-disabled: #ccc;
  --button-light-text-color: #6269ff;
  --button-light-background-color: #fff;
  --button-dark-text-color: #fff;
  --button-dark-background-color: #6269ff;
  --button-dark-background-disabled-color: #c2c9ff;
  --button-outline-text-color: #3c396f;
  --button-outline-background-color: #fff;
  --button-outline-border-color: #c6c6c6;
  --button-background-color-disabled: #c2c9ff;
  --box-shadows-navbar: 0 10px 30px 0 rgba(0,0,0,.08);
  --box-shadows-images: -17px 28px 14px 0 rgba(0, 0, 0, 0.21),
    0 0 10px 0 rgba(0, 0, 0, 0.04);
  --color-success: #17e3ae;
  --color-success-bg: #f1fefa;
  --color-error: #fa1478;
  --color-error-bg: #fff1f7;
  --color-warning: orange;
  --color-info: blue;
  --tooltip-background-color: #202020;
  --grey-bg: #eeeff0;
  --grey-lightest: #F7F8FA;
  --color-white: #fff;
  --footer-mini-block-color: #FFFFFF;
  --footer-mini-right-block-color: #c6c6c6;
  --newsletter-mobile-bg: #2e2e53;
  --mobile-footer-links-border: rgba(234, 234, 234, 0.2);
  --header-links-color: #111111;
  --new-secondary-text-color: #111111;
  --grey-lightest: #F7F8FA;
}

:root {
  --padding-hor: 90px;
  --content-width: 844px;
  --modal-padding-hor: 50px;
}

@media screen and (max-width: 759px) {
  :root {
    --padding-hor: 24px;
    --content-width: 100%;
    --modal-padding-hor: 20px;
  }
}

@media screen and (min-width: 760px) and (max-width: 991px) {
  :root {
    --padding-hor: 60px;
    --content-width: 100%;
    --modal-padding-hor: 50px;
  }
}

@media screen and (min-width: 1200px)  {
  :root {
    --padding-hor: 90px;
    --content-width: 844px;
    --modal-padding-hor: 50px;
  }
}
