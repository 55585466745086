.membershipButton-15Dw-MgM {
  position: absolute;
  cursor:pointer;
  font-size: 12px;
  font-weight: 500;
  line-height: 1;
  color: var(--color-white);
  padding: 0 19px 0 25px;
  background: var(--tertiary-text-color);
  border-radius: 25px;
  border: solid 1px var(--tertiary-text-color);
  z-index: 1;
  min-width: 370px;
  max-width: 100%;
  text-align: left;
  display: flex;
  min-height: 50px;
  align-items: center;
  letter-spacing: 0.3px;
}

.membershipButton-15Dw-MgM i {
  font-size: 16px;
  font-style: normal;
  display: block;
  padding-bottom: 1px;
}
.membershipButton-15Dw-MgM em {
  font-style: normal;
}
.membershipButton-15Dw-MgM span:last-child {
  font-size: 26px;
  padding-left: 10px;
  text-align: right;
  position: absolute;
  right: 19px;
  letter-spacing: normal;
}
.techLink-285k0wWk {
  color: var(--navigation-text-color);
}
