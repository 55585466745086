.overlay-Dbi4rYv1 {
  position: fixed;
  background-color: var(--body-background-color);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  z-index: 13;
}

.overlay-content-2C616-9m {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  background-color: var(--secondary-background-color);
}

.sticky-footer-partner ~ .overlay-Dbi4rYv1 .overlay-video {
  height: auto;
  bottom: 65px;
}

.sticky-footer-partner ~ .overlay-Dbi4rYv1 .overlay-close-rjKA-wYO {
  bottom: 80px;
}

.sticky-footer-cookie ~ .overlay-Dbi4rYv1 .overlay-video {
  height: auto;
  bottom: 100px;
}

.sticky-footer-cookie ~ .overlay-Dbi4rYv1 .overlay-close-rjKA-wYO {
  bottom: 120px;
}

.overlay-close-rjKA-wYO {
  position: fixed;
  bottom: 25px;
  left: 50%;
  transform: translate(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--primary-text-color);
  height: 50px;
  width: 50px;
  border: 0;
  border-radius: 100%;
  cursor: pointer;
  z-index: 1;
}

.theme-promo-sunrise .overlay-close-rjKA-wYO {
  background-color: #e6003c;
}

.overlay-spinner-3P96gkUk {
  width: 50px;
  height: 50px;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.slide-up-RLKiEGWF {
  -webkit-animation: slideUp-3kRH0VWo .6s cubic-bezier(0.215, 0.61, 0.355, 1);
  -moz-animation: slideUp-3kRH0VWo .6s cubic-bezier(0.215, 0.61, 0.355, 1);
  -ms-animation: slideUp-3kRH0VWo .6s cubic-bezier(0.215, 0.61, 0.355, 1);
  -o-animation: slideUp-3kRH0VWo .6s cubic-bezier(0.215, 0.61, 0.355, 1);
  animation: slideUp-3kRH0VWo .6s cubic-bezier(0.215, 0.61, 0.355, 1);
}

@keyframes slideUp-3kRH0VWo {
  from { transform: translate(-50%, 100px); }
  to  { transform: translate(-50%, 0); }
}

@-moz-keyframes slideUp-3kRH0VWo {
  from { -moz-transform: translate(-50%, 100px); }
  to   { -moz-transform: translate(-50%, 0px); }
}

@-webkit-keyframes slideUp-3kRH0VWo {
  from { -webkit-transform: translate(-50%, 100px); }
  to   { -webkit-transform: translate(-50%, 0px); }
}

@-ms-keyframes slideUp-3kRH0VWo {
  from { -ms-transform: translate(-50%, 100px); }
  to   { -ms-transform: translate(-50%, 0px); }
}

@-o-keyframes slideUp-3kRH0VWo {
  from { -o-transform: translate(-50%, 100px); }
  to   { -o-transform: translate(-50%, 0px); }
}

@media only screen and (max-width: 992px) {
  .theme-promo-sunrise .home-benefits-overlay .overlay-close-rjKA-wYO {
    bottom: 54px;
  }
}

@media only screen and (min-width: 993px) {
  .theme-promo-sunrise .home-benefits-overlay .overlay-close-rjKA-wYO {
    bottom: 100px;
  }
}
